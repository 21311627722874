html {
  font-family: Roboto;
}

.wrapper {
  font-family: sans-serif;
  height: 100vh;
}

.main-panel {
  text-align: center;
  overflow: auto;
}

.DateInput.DateInput_1 {
  width: 210px;
}

.SingleDatePicker_picker {
  z-index: 100000;
}
